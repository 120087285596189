// WebSocket
let ws = null;
let wsKeepAlive = false;
let messageHandlers = new Set();

export function wsReconnect(userId) {
    try {
        if (ws) {
            // make sure we close existing connection before opening a new one
            try {
                ws.close(1000);
            } catch(err) {
                console.log(`Error while closing WS before reconnect `, err);
            }
        }
        if (!userId) return; // don't bother if we're not passing in a userId
        wsKeepAlive = true;

        const port = process && (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? '3000' : window.location.port;
        ws = new WebSocket(`${window.location.protocol.toLowerCase().startsWith('https') ? 'wss' : 'ws'}://${window.location.hostname}:${port}`);

        ws.addEventListener("open", () => {
            console.log("WebSocket connected. Registering...");
            // Register the socket with the current user's id
            ws.send(JSON.stringify({type: "register", userId}));
        });

        // Listen for messages
        ws.addEventListener("message", event => {
            if (event.data) {
                let msg = JSON.parse(event.data);
                if (msg.event === 'ping') {
                    ws.send(JSON.stringify({event: 'pong'}));
                } else {
                    messageHandlers.forEach(mh => mh(msg));
                }
            }
        });

        // If socket is closed due to error, reconnect in 2 sec
        ws.addEventListener("error", event => {
            console.log("WebSocket error: ", event);
            if (wsKeepAlive) {
                window.setTimeout(() => wsReconnect(userId), 2000);
            }
        });
        ws.addEventListener("close", event => {
            console.log("WebSocket closed");
            if (wsKeepAlive) {
                window.setTimeout(() => wsReconnect(userId), 2000);
            }
        });
    } catch(err) {
        console.log("Error opening WebSocket: ", err);
        // try again in 5 sec
        if (wsKeepAlive) {
            window.setTimeout(() => wsReconnect(userId), 2000);
        }
    }
}

export function isWebSocketConnecting() {
    if (ws && ws.readyState == 0) {
        return true;
    }
    return false;
}


export function isWebSocketConnected() {
    if (ws && ws.readyState == 1) {
        return true;
    }
    return false;
}

export function closeWebSocket() {
    if (ws) {
        try {
            wsKeepAlive = false; // Disable auto reconnect
            ws.close(1000);
            ws = null;
        } catch(err) {
            console.log(`Error while closing websocket: ${err}`);
        }
    }
}

export function addWsMessageHandler(fn) {
    messageHandlers.add(fn);
}

export function removeWsMessageHandler(fn) {
    messageHandlers.delete(fn);
}